.topics{
    height: 100px;
    padding: 40px;
    width: 80%;
    margin: auto;
    text-align: center;
    margin-bottom: 50px;
    margin-top: 20px;
}

.left{
    transform: rotate(-90deg);
    opacity: 0.6;
}

.right{
    transform: rotate(90deg);
    opacity: 0.6;
}

.bottom{
    opacity: 0.6;
    &.active {
        transform: rotate(180deg);
    }
}

.right:hover, .left:hover, .bottom:hover{
    opacity: 1;
    cursor: pointer;
}


.slider{
    display: flex;
    margin: auto;
    width: 40%;
    justify-content:  center
}

h2{
    color: white;
    font-family: 'Dongle', sans-serif;
    font-size: 2rem;
    font-weight: bolder;
    margin-top: -5px;
    margin-left: 20px;
    margin-right: 20px;
}

button{
    background-color: transparent;
    border: none;
}

