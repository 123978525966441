h1{
    font-family: 'Dongle', sans-serif;
    font-size: 10vw;
    font-weight: bolder;
    letter-spacing: -1px; 
    color : rgb(255, 255, 255);
    text-shadow: 1px 7px 10px #000000;
    display: flex;
    justify-content: space-between;
    
}
