article{
    margin: auto;
    width: 70%;
    background: #fff;
    box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
    padding: 25px;
    border-radius: 25px;
    min-height: 50vh;

}

.postContent{
    padding: 0.5vh 10% 2vh 0;
}

img{
    margin-bottom: 30px;
    border-radius: 20px;
    width: 80%;
}

h3{
    font-weight: bolder;
}

p{
    line-height: 25px;
}