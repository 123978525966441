@media only screen and (max-width: 1120px){
  .leading-text{
    display: none;
  }
  .slides {
    align-self: flex-start;
    flex: 1;
    width: 100%;
    overflow: hidden;
    margin-left: 0px;
  }
  .outer-container .carousel-wrapper p,
  .carousel-item {
    font-weight: 700;
    font-size: 0.6rem;
    color: var(--white);
    line-height: 1;
  }
 }

 @media only screen and (min-width: 1120px){
  .leading-text {
    display: flex;
    align-items: center;
    height: 100%;
    padding-bottom: 13px;
  }
  .slides {
    align-self: flex-start;
    flex: 1;
    width: 100%;
    overflow: hidden;
    margin-left: 20px;
  }
  .outer-container .carousel-wrapper p,
  .carousel-item {
    font-weight: 700;
    font-size: 1rem;
    color: var(--white);
    line-height: 1.3;
  }

 }



:root {
    --white: #fff;
  
  }
  
  * {
    box-sizing: border-box;
  }
  
  body {
    font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  button {
    cursor: pointer;
  }
  
  img {
    max-width: 100%;
  }

  .outer-container {
 
    margin-left: 10vw;
    display: flex;

    border-radius: 5px;
    
    overflow: hidden;
  }

  .carousel{

      width: 15vw;
  }
  
  .outer-container > div {
    padding: 20px;
  }
  
  .outer-container .carousel-wrapper {
    background: var(--orange);
    display: flex;
    flex-direction: column;
  }
  

  

  

  
  .carousel {
    height: 100%;
    display: flex;
    flex: 5;
    align-items: center;
    color: var(--white);
  }
  
  .carousel-button {
    display: flex;
    align-self: center;
    padding: 10px;
    max-width: 40px;

    background: var(--orange);
    border: none;
  }
  
  .carousel-button svg {
    height: 26px;
    width: 26px;
  }
  .carousel-button path {
    fill: var(--white);
  }
  
  
  .carousel-inner {
    position: relative;
    max-height: 250px;
    height: 250px;
  }
  .carousel-inner::before{
    display: none;
  }
  
  
  .carousel-inner::before,
  .carousel-inner::after {
    content: "";
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 110px;
    pointer-events: none;
  }
  
  /* .carousel-inner::before {
    top: 0;
    background: linear-gradient(
      0deg,
      hsla(0, 0%, 100%, 0) 0%,
      hsla(27, 100%, 48%, 1) 200%
    );
  } */
  
  /* .carousel-inner::after {
    bottom: 0;
    background: linear-gradient(
      0deg,
      hsla(27, 100%, 48%, 1) 0%,
      hsla(0, 0%, 100%, 0) 100%
    );
  } */
  
  .carousel-item {
    position: absolute;
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    opacity: 0;
    top: 112px;
    transition: transform 0.4s ease, opacity 0.4s ease;

  }
  
  .carousel-item.visible {
    opacity: 0.5;
  }

  .carousel-item.active {
    opacity: 1;
  }
  