nav {
    opacity: 1;
    top: 17vh;
    right: 0;
    z-index: 1;
    width: 100%;
    position: absolute;
    .menu {
      top: 0;
      left: 0;
      margin: 0;
      z-index: 1;
      width: 100%;
      background: #141414f3;
      padding: 3em 0 2em;
      position: absolute;
      transform: translateY(-140%);
      transition: transform 0.3s cubic-bezier(1,0.5,0,1);
      box-shadow: 1px 7px 40px #000000;
      &.active {
        transform: translateY(0);
        height: 83vh;
      }
      li {
        list-style: none;
      
        a {
          padding: 1em;
          color: #fff;
          display: block;
          background: none;
          line-height: 1em;
          font-size: 1.125em;
          text-align: center;
          text-decoration: none;
          transition: background 0.2s ease-in-out;
          &:hover, &:focus, &:active {
            background: linear-gradient(147deg, #392bff41 0%, #fd383849 88%);
            box-shadow: 1px 3px 5px #00000054;
          }
        }
      }
    }
  }
  

