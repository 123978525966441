body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;

}

html,body { height:100%; }

#root{
  width: 100%;
}


header{
  margin-left: 20vw;
  margin-top: 20px;
  margin-right: 20vw;
}

.post-container{

  display: grid; 
  grid-template-columns: 0.05fr 1fr; 
  grid-template-rows: 1fr; 
  gap: 0px 0px; 
  grid-template-areas: 
    ". ."; 
}